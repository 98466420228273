import { h, Component } from 'preact';
import styles from './styles.scss';
import EditionSelect from '../../shared/edition-select';
import { I18n } from '../../shared/context';
import { DestinationLogo } from '../../shared/svg/logos';
import { UseNewsletterColorIcon } from '../../shared/svg/icons/newsletter';
import { UseCaretIcon } from '../../shared/svg/icons/caret';
import { Link } from '../../shared/link';
import { Button } from '../../shared/button';
import { NavLabel } from '../../shared/nav-label';
import { withTheme } from '../../shared/withTheme';
import DESTINATIONS from '../../constants/destinations';
import { trackNavAction } from '../tracking/clientEventTracking';
import { PyramidScheme } from '../../shared/svg/logos/pyramid-scheme-hamburger';

let FocusTrap;
if (typeof window !== 'undefined') {
  FocusTrap = require('focus-trap-react');
}

const navMenuId = 'js-more-nav';

const destinationItemTitleId = name => `js-destination-item-${name}-more`;

class Toggle extends Component {
  render({ children, theme, ...props }) {
    return (
      <Button {...props} className={`${styles.menuToggle} ${theme.menuToggle}`} onClick={this.props.onClick} location={'hamburger'}>
        <i>{children}</i>
      </Button>
    );
  }
}

export const ThemedToggle = withTheme(Toggle);

export class PageOverlay extends Component {
  constructor() {
    super();
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(evt) {
    // Escape key
    if (evt.keyCode === 27) {
      this.props.onHide(evt);
    }
  }

  componentDidMount() {
    document.body.classList.add(styles.bodyWithMoreNav);
    window.addEventListener('keydown', this.onKeyDown, true);
  }

  componentWillUnmount() {
    document.body.classList.remove(styles.bodyWithMoreNav);
    window.removeEventListener('keydown', this.onKeyDown, true);
  }

  render({ top, onHide }) {
    const topDistance = parseInt(top, 10);

    return (
      <div className={styles.pageOverlay} style={`top:${topDistance}px`} onMouseDown={onHide} role="presentation" />
    );
  }
}

const SubSectionItem = withTheme(({ name, logo, url, label, nav_label, nav_label_type, theme }) => {
  const shoppingSubSectionsExcluded = ['Kids', 'Sex Toys', 'Stores', 'Subscriptions'];

  const getLogo = (logoName) => {
    if (logoName === 'PyramidScheme') {
      return <PyramidScheme width={114} height={9} />;
    }

    return name;
  };

  if(shoppingSubSectionsExcluded.includes(name)) {
    return '';
  }

  return (
    <li>
      <Link href={url} className={`${styles.link} ${theme.link}`} label={label || name} location={'moreMenu'}>
        {logo ? getLogo(logo) : name}
        <NavLabel navLabelType={nav_label_type}>{nav_label}</NavLabel>
      </Link>
    </li>
  );
});

const SubSectionPageItem = withTheme(({ name, url, label, theme }) => (
  <li><Link href={url} className={`${styles.link} ${theme.link}`} label={label || name} location={'moreMenu'} isInfoPage={true}>{name}</Link></li>
));

const SectionItem = withTheme(({ name, url, label, theme, subSections = [] }) => {
  const hasSubSections = subSections.length > 0;

  return (
    <nav className={`${styles.section} ${styles.sectionLinksSection}`} aria-label={name} >
      <h3 className={`${styles.sectionTitle} ${theme.text}`}>
        {url ? (
          <Link href={url} className={`${styles.link} ${theme.link}`} label={label || name} location={'moreMenu'}>{name}</Link>
        ) : (
          name
        )}
      </h3>
      {hasSubSections &&
        <ul className={styles.sectionItems}>{subSections.map(SubSectionItem)}</ul>
      }
    </nav>
  );
});

const HeaderItem = withTheme(({ name, url, description, label = '', theme }) => (
  <Link href={url} className={`${styles.headerLink} ${styles.link} ${theme.link}`} label={description} location={'moreMenu'} trackingData={{
    unit_type: 'nav',
    unit_name: 'hidden',
    item_type: 'text',
    item_name: label,
    target_content_type: 'feed',
    target_content_id: label,
  }}>
    <div className={`${styles.headerSection}`}>
      {(label === 'newsletters') &&
      <div>
        <UseNewsletterColorIcon className={styles.newsletterIcon} aria-hidden={true} height={33} width={28} />
      </div>
      }
      <div className={`${styles.headerCta}`}>
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
      <div>
        <UseCaretIcon className={styles.rightCaret} aria-hidden={true} />
      </div>
    </div>
  </Link>
));

const MoreNavInner = ({ navItems: { sections = [], about = [], header = [], footer = [], community = [], destinations = [], fromPartners = [], giftGuides = [] }, theme }) => {
  const hasDestinations = destinations.length > 0;
  const hasHeader = header.length > 0;
  const hasSections = sections.length > 0;
  const hasCommunity = community.length > 0;
  const hasFromPartners = fromPartners.length > 0;
  const hasGiftGuides = giftGuides.length > 0;

  return (
    <div className={styles.moreNavInner}>
      <I18n.Consumer>
        {i18n =>
          <h2 className={styles.moreNavLabel} id="nav-menu-title"> {i18n.browse_links} </h2>
        }
      </I18n.Consumer>
      <div className={styles.sectionsSection}>
        {/* header section for feature banner */}
        {hasHeader &&
          <div className={styles.headerSectionContainer}>
            {header.map(HeaderItem)}
          </div>
        }
        {/* primary sections */}
        {hasSections &&
          sections.map(SectionItem)
        }
        {hasCommunity && (
          <I18n.Consumer>
            {i18n =>
              <nav className={`${styles.section} ${styles.sectionLinksSection}`} aria-label={i18n.community}>
                <h3 className={`${styles.sectionTitle} ${theme.text}`}>
                  <Link href='/community' className={`${styles.link} ${theme.link}`} label={i18n.community} location={'moreMenu'} isInfoPage={true}>{i18n.community}</Link>
                </h3>
                <ul className={styles.sectionItems}>{community.map(SubSectionPageItem)}</ul>
              </nav>
            }
          </I18n.Consumer>
        )}
        {/* gift guides  */}
        {hasGiftGuides && (
          <I18n.Consumer>
            {i18n =>
              <nav className={`${styles.section} ${styles.sectionLinksSection}`} aria-label={i18n.gift_guides}>
                <h3 className={`${styles.sectionTitle} ${theme.text}`}>{i18n.gift_guides}</h3>
                <ul className={styles.sectionItems}>{ giftGuides.map(SubSectionPageItem) }</ul>
              </nav>
            }
          </I18n.Consumer>
        )}
        {/* from our partners  */}
        {hasFromPartners && (
          <I18n.Consumer>
            {i18n =>
              <nav className={`${styles.section} ${styles.sectionLinksSection}`} aria-label={i18n.from_partners}>
                <h3 className={`${styles.sectionTitle} ${theme.text}`}>{i18n.from_partners}</h3>
                <ul className={styles.sectionItems}>{ fromPartners.map(SubSectionPageItem) }</ul>
              </nav>
            }
          </I18n.Consumer>
        )}
        {/* about section */}
        <I18n.Consumer>
          {i18n =>
            <nav className={`${styles.section} ${styles.sectionLinksSection}`} aria-label={i18n.about}>
              <h3 className={`${styles.sectionTitle} ${theme.text}`}>{i18n.about}</h3>
              <ul className={styles.sectionItems}>{ about.map(SubSectionPageItem) }</ul>
            </nav>
          }
        </I18n.Consumer>
        {/* edition & copyright */}
        <div className={`${styles.section} ${styles.footerSectionContainer}`}>
          <div className={`${styles.footerSection}`}>
            <div className={styles.footerSubSection}>
              <EditionSelect />
            </div>
            <div id={'CCPAModule'} className={`${styles.CCPAConsentModule}`}>
              <div className={`${styles.ccpaCopy} ${theme.text}`}>
                US residents can opt out of "sales" of personal data.
              </div>
              <Button
                className={`ot-sdk-show-settings ${styles.smallSecondaryButton}`}
                onClick={() => {trackNavAction({location: 'usprivacy', action_value: 'ccpa_module'}); }}
                id="ot-sdk-btn"
              >
                Do Not Sell or Share My Personal Information
              </Button>
            </div>
            <div id={'GDPRModule'} className={`${styles.GDPRConsentModule}`}>
              {/* eslint-disable new-cap */}
              <Button
                className={`ot-gdpr-btn ${styles.smallSecondaryButton}`}
                onClick={() => { window.OneTrust.ToggleInfoDisplay(); }}
              >
                Privacy Settings
              </Button>
              {/* eslint-enable new-cap */}
            </div>
            <div id={'IDNMLModule'} className={`${styles.IDNMLConsentModule}`}>
              <Button
                className={`idnml-sp-btn ${styles.smallSecondaryButton}`}
                onClick={() => {window._sp_.gdpr.loadPrivacyManagerModal(1161871, 'purposes'); }}
              >
                Privacy Settings
              </Button>
            </div>
          </div>
          <div className={`${styles.footerNav}`}>
            <ul>
              <li className={`${styles.copyright} ${theme.text}`}>  © {new Date().getFullYear()} BuzzFeed, Inc </li>
              { footer.map(SubSectionPageItem) }
            </ul>
          </div>
        </div>
      </div>
      {/* destination nav */}
      {hasDestinations &&
        <I18n.Consumer>
          {i18n =>
            <nav className={`${styles.section} ${styles.destinationsSection}`} aria-label={i18n.browse_brands}>
              <ul className={styles.sectionItems}>
                {
                  destinations.map(({url, name, description, label}) => (
                    <li className={styles.destinationItem}>
                      <Link className={styles.destinationLink} href={url} location={'moreMenu'} label={label || name}>
                        <h3>
                          <i className={styles[`${name}Logo`]}>
                            <DestinationLogo destinationName={name} role="img" aria-labelledby={destinationItemTitleId(name)}>
                              <title id={destinationItemTitleId(name)}>{DESTINATIONS[name].displayName}</title>
                            </DestinationLogo>
                          </i>
                        </h3>
                        <span>{description}</span>
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </nav>
          }
        </I18n.Consumer>
      }
    </div>
  );
};

class MoreNav extends Component {
  render({ isVisible, ...props }) {
    const hasDestinations = props.navItems && props.navItems.destinations && props.navItems.destinations.length > 0;

    return (
      <section className={`${styles.moreNav} ${props.theme.moreNav} ${isVisible ? styles.visible : ''} ${hasDestinations ? styles.withDestinations : ''}`} id={navMenuId} ref={container => this.container = container} aria-labelledby="nav-menu-title" role="dialog" aria-modal="true">
        { isVisible ? <FocusTrap focusTrapOptions={{clickOutsideDeactivates: true}}><MoreNavInner {...props} /></FocusTrap> : <MoreNavInner {...props} /> }
      </section>
    );
  }
}

export const ThemedMoreNav = withTheme(MoreNav);
