import { h } from 'preact';
import { SvgUse } from '..';


const attributes = {
  id: 'newsletter-icon',
  width: 32,
  height: 32
};

export const NewsletterIcon = () => (
  <symbol {...attributes}>
    <path d="M20.9076 11.8393L11.352 19.677C11.2131 19.7973 11.1334 19.972 11.1334 20.1558V24.3019C11.1334 24.9108 11.6384 25.49 12.4008 24.9355L13.6559 24.0274M15.5688 21.1337L21.3658 25.1923C21.7379 25.4528 22.2551 25.2487 22.3491 24.8043L26.119 6.97466C26.2187 6.50321 25.7765 6.09743 25.3153 6.23718L4.80261 12.4531C4.2922 12.6078 4.18954 13.2854 4.63123 13.5843L9.23246 16.6983" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>
);

export const UseNewsletterIcon = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);

const attributesColor = {
  id: 'newsletter-color-icon',
  width: 28,
  height: 33
};

export const NewsletterColorIcon = () => (
  <symbol {...attributesColor}>
    <path d="M0.5 32.5V16.501L13.6 9.56575L26.7 16.501V32.5H0.5Z" fill="#FBF6EC" stroke="black"/>
    <path d="M24.8 12.2H2.39999V16.9562L13.6 21L24.8 16.9562V12.2Z" fill="white" stroke="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.895943 4.52925C1.07778 4.34742 1.37259 4.34742 1.55442 4.52925L4.8468 7.82163C5.02864 8.00347 5.02864 8.29828 4.8468 8.48011C4.66497 8.66194 4.37016 8.66194 4.18833 8.48011L0.895943 5.18773C0.71411 5.00589 0.71411 4.71108 0.895943 4.52925Z" fill="black" stroke="black" stroke-width="0.3" stroke-linecap="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.161 4.67076C26.3428 4.85259 26.3428 5.14741 26.161 5.32924L22.8686 8.62162C22.6868 8.80346 22.392 8.80345 22.2101 8.62162C22.0283 8.43979 22.0283 8.14498 22.2101 7.96314L25.5025 4.67076C25.6844 4.48893 25.9792 4.48893 26.161 4.67076Z" fill="black" stroke="black" stroke-width="0.3" stroke-linecap="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6027 1C13.8599 1 14.0684 1.20846 14.0684 1.46561L14.0684 6.12175C14.0684 6.3789 13.8599 6.58736 13.6027 6.58736C13.3456 6.58736 13.1371 6.3789 13.1371 6.12175L13.1371 1.46561C13.1371 1.20846 13.3456 1 13.6027 1Z" fill="black" stroke="black" stroke-width="0.3" stroke-linecap="round"/>
  </symbol>
);

export const UseNewsletterColorIcon = ({...props}) => (
  <SvgUse id={attributesColor.id} viewBox={`0 0 ${attributesColor.width} ${attributesColor.height}`} {...props} />
);
