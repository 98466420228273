import { h } from 'preact';
import styles from './styles.scss';
import { UseNewsletterIcon } from '../../shared/svg/icons/newsletter';
import { Config } from '../../shared/context';
import { Link } from '../../shared/link';
import { withTheme } from '../../shared/withTheme';


export const Newsletters = withTheme(({ theme }) => {
  return (
    <Config.Consumer>{ () => (
      <div className={styles.newsletters}>
        <div>
          <Link className={styles.newslettersLink} href={'/newsletters?origin=navIcon'} label={'newsletters'} location={'newsletters'}>
            <UseNewsletterIcon height={20} width={20} className={`${styles.newslettersIcon} ${theme.newslettersIcon}`} aria-label={'Newsletters'} />
          </Link>
        </div>
      </div>
    )}</Config.Consumer>
  );
});
